import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

import { selectApiKey, selectUserId } from 'redux/auth/saga.selectors'
import {
  getUserConfig,
} from 'services/loadAnalysisApi'

import * as actions from './actions'

function* fetchUserConfig() {
  yield takeEvery(actions.USER_CONFIG_FETCH, function* sg() {
    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()

    let res = null
    try {
      res = yield call(getUserConfig, apiKey, userId)
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter os dados'
      yield put({ type: actions.USER_CONFIG_FETCH_FAILED, payload: messageError })
      return
    }

    const payload = res.data

    yield put({ type: actions.USER_CONFIG_FETCH_SUCCEEDED, payload: payload })
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchUserConfig),
  ])
}
