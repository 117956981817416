import _ from 'lodash'
import axios from './axiosCustom'

const HOST = process.env.REACT_APP_POSEIDON_ANALYSIS_HOST

const headers = (apiKey) => {
  const objHeaders = {
    'Content-type': 'application/json',
    'x-api-key': apiKey,
  }

  return objHeaders
}

export const containerSort = (apiKey, userInternalId, thirdVeredict = false) => {
  let queryString = `user_internal_id=${userInternalId}`
  queryString += `&third_veredict=${thirdVeredict}`

  return axios({
    method: 'GET',
    url: `${HOST}/analysis/container?${queryString}`,
    headers: headers(apiKey),
  })
}

export const createOperation = (apiKey, sendData) => {
  const data = sendData

  return axios({
    method: 'POST',
    url: `${HOST}/operations/analysis`,
    headers: headers(apiKey),
    data: data,
  })
}

export const putEmptyContainer = (apiKey, operationId, emptyContainer) => {
  const data = {
    empty_container: emptyContainer,
    operation_id: operationId,
  }

  return axios({
    method: 'PUT',
    url: `${HOST}/operations/analysis`,
    headers: headers(apiKey),
    data: data,
  })
}

export const createSuspect = (apiKey, operationId) => {
  const data = {
    operation_id: operationId,
  }

  return axios({
    method: 'POST',
    url: `${HOST}/operations/suspicions`,
    headers: headers(apiKey),
    data: data,
  })
}

export const putSuspectCoordinates = (apiKey, operationId, suspicionId, crop, documentId, width, height) => {
  const data = {
    top: crop.y,
    bottom: crop.height,
    right: crop.x,
    left: crop.width,
    document_id: documentId,
    width: width,
    height: height,
    operation_id: operationId,
    suspicion_id: suspicionId,
  }

  return axios({
    method: 'PUT',
    url: `${HOST}/operations/suspicions/coordinates`,
    headers: headers(apiKey),
    data: data,
  })
}

export const putSuspectType = (apiKey, operationId, suspicionId, suspicionsType, suspicionsComment) => {
  const data = {
    comment: suspicionsComment,
    type: suspicionsType,
    operation_id: operationId,
    suspicion_id: suspicionId,
  }

  return axios({
    method: 'PUT',
    url: `${HOST}/operations/suspicions/type`,
    headers: headers(apiKey),
    data: data,
  })
}

export const listSuspects = (apiKey, operationId, imgInternalId = '') => {
  let queryString = `operation_id=${operationId}`
  queryString += !_.isEmpty(imgInternalId) ? `&document_id=${imgInternalId}` : ''

  return axios({
    method: 'GET',
    url: `${HOST}/operations/suspicions?${queryString}`,
    headers: headers(apiKey),
  })
}

export const deleteSuspect = (apiKey, operationId, suspicionId) => {
  let queryString = `operation_id=${operationId}`
  queryString += `&suspicion_id=${suspicionId}`

  return axios({
    method: 'DELETE',
    url: `${HOST}/operations/suspicions?${queryString}`,
    headers: headers(apiKey),
  })
}

export const finalizeOperation = (apiKey, sendData) => {
  const data = sendData

  return axios({
    method: 'POST',
    url: `${HOST}/operations/analysis/finalize`,
    headers: headers(apiKey),
    data: data,
  })
}
