/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import {
  Grid,
  Input,
  FormControlLabel,
  FormGroup,
  Fab,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  CircularProgress,
  TablePagination,
  Radio,
} from '@material-ui/core'
import { HourglassEmpty, FiberManualRecord } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'

import { listVehicles } from 'services/loadAnalysisApi'

import styles from './styles'

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
  },
  body: {
    fontSize: 16,
    color: '#666666',
  },
}))(TableCell)

const ProcessStatusWithIcon = (props) => {
  let colorStatus = 'white'
  let textStatus = ''

  switch (props.status) {
    case 'green':
      colorStatus = 'green'
      textStatus = 'OK'
      break
    case 'yellow':
      colorStatus = 'yellow'
      textStatus = 'Inconsistente'
      break
    case 'red':
      colorStatus = 'red'
      textStatus = 'Suspeito'
      break
    case 'grey':
      colorStatus = 'gray'
      textStatus = 'Pendente'
      break
    default:
      colorStatus = 'white'
      textStatus = ''
      break
  }
  return (
    <span style={{ color: colorStatus }}>
      <FiberManualRecord />
      {' '}
      <span style={{ fontFamily: 'Roboto Regular', color: '#666666' }}>{textStatus}</span>
    </span>
  )
}

const StatusVeredictTxt = (props) => {
  const { veredict } = props
  let colorStatus = 'white'

  switch (veredict) {
    case 'No Suspect':
      colorStatus = 'green'
      break
    case 'With Suspect':
      colorStatus = 'red'
      break
    default:
      colorStatus = 'white'
      break
  }

  return (
    <span style={{ color: colorStatus }}>
      <FiberManualRecord />
      {' '}
    </span>
  )
}

const ContainersList = () => {
  const classes = styles()

  const apiKey = useSelector(state => state.auth.api_key)

  const [data, setData] = useState([])
  const [searching, setSearching] = useState(false)
  const [beginDate, setBeginDate] = useState(moment().subtract(6, 'months').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [selectedFilterColorValue, setSelectedFilterColorValue] = useState('all')
  const [pagination, setPagination] = useState({
    perPage: 15,
    currentPage: 1,
    totalItens: 0,
  })

  const loadVehicles = async (beginVal, endVal, page = '1', status = 'all') => {
    setData([])
    setSearching(true)

    try {
      const response = await listVehicles(apiKey, beginVal, endVal, page, status)

      setData(response.data)
      setPagination({
        perPage: parseInt(response.headers['x-per-page'], 10),
        currentPage: parseInt(response.headers['x-page'], 10),
        totalItens: parseInt(response.headers['x-total'], 10),
      })
      setSearching(false)
      setSelectedFilterColorValue(status)
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }
    }
  }

  useEffect(() => {
    const url = window.location.href
    const processStatus = url.split('process_status=')
    if (processStatus[1] === 'ok') {
      loadVehicles(beginDate, endDate, '1', 'green')
    } else if (processStatus[1] === 'inconsistentes') {
      loadVehicles(beginDate, endDate, '1', 'yellow')
    } else if (processStatus[1] === 'suspeitos') {
      loadVehicles(beginDate, endDate, '1', 'red')
    } else if (processStatus[1] === 'pendentes') {
      loadVehicles(beginDate, endDate, '1', 'grey')
    } else {
      loadVehicles(beginDate, endDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterClick = () => {
    loadVehicles(beginDate, endDate, '1', selectedFilterColorValue)
  }

  const handleChangePage = (e, page) => {
    loadVehicles(beginDate, endDate, (page + 1).toString(), selectedFilterColorValue)
  }

  const sanitizeDate = (date) => {
    if (_.isEmpty(date)) return ''

    try {
      const dateTxt = moment(date).format('DD-MM-YYYY HH:mm')
      return dateTxt === 'Invalid date' ? '' : dateTxt
    } catch (e) {
      return ''
    }
  }

  const renderOperations = (operations) => {
    const newOperations = _.cloneDeep(operations)

    // fills with till 3 items
    for (let i = 0; newOperations.length - 3; i += 1) {
      newOperations.push({})
    }

    return _.map(newOperations, (operation, i) => (
      <React.Fragment key={i.toString()}>
        <CustomTableCell align="center">
          <StatusVeredictTxt
            veredict={operation.veredict}
          />
          {operation.ia_veredict === true && <p style={{ fontSize: 10 }}>(Veredito da IA)</p>}
        </CustomTableCell>
        <CustomTableCell align="left">
          {sanitizeDate(operation.date)}
        </CustomTableCell>
      </React.Fragment>
    ))
  }

  return (
    <div className={classes.root}>

      <Grid container style={{ backgroundColor: '#666666', padding: '50px' }}>
        <Grid item xs={2}>
          <Typography className={classes.titleCardWhite}>Containers</Typography>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#ffffff',
                fontSize: '14px',
              }}
            >
              <HourglassEmpty />
              {' '}
              <span>Listar por</span>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={5}
          style={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: '#ffffff',
              fontSize: '14px',
            }}
          >
            <span
              style={{
                fontSize: '14px',
                fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                fontWeight: '700',
              }}
            >
              Status Processo
            </span>
            <div>
              <FormGroup row>
                <FormControlLabel
                  value="all"
                  label={(
                    <span
                      style={{
                        fontSize: '14px',
                        fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                        color: 'white',
                      }}
                    >
                      Todos
                    </span>
)}
                  control={<Radio />}
                  checked={selectedFilterColorValue === 'all'}
                  onChange={(e) => {
                    setSelectedFilterColorValue(e.target.value)
                  }}
                />
                <FormControlLabel
                  value="green"
                  label={(
                    <span
                      style={{
                        fontSize: '14px',
                        fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                        color: 'white',
                      }}
                    >
                      Ok
                    </span>
)}
                  control={<Radio />}
                  checked={selectedFilterColorValue === 'green'}
                  onChange={(e) => {
                    setSelectedFilterColorValue(e.target.value)
                  }}
                />
                <FormControlLabel
                  value="yellow"
                  label={(
                    <span
                      style={{
                        fontSize: '14px',
                        fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                        color: 'white',
                      }}
                    >
                      Inconsistente
                    </span>
)}
                  control={<Radio />}
                  checked={selectedFilterColorValue === 'yellow'}
                  onChange={(e) => {
                    setSelectedFilterColorValue(e.target.value)
                  }}
                />
                <FormControlLabel
                  value="red"
                  label={(
                    <span
                      style={{
                        fontSize: '14px',
                        fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                        color: 'white',
                      }}
                    >
                      Suspeito
                    </span>
)}
                  control={<Radio />}
                  checked={selectedFilterColorValue === 'red'}
                  onChange={(e) => {
                    setSelectedFilterColorValue(e.target.value)
                  }}
                />
                <FormControlLabel
                  value="grey"
                  label={(
                    <span
                      style={{
                        fontSize: '14px',
                        fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                        color: 'white',
                      }}
                    >
                      Pendente
                    </span>
)}
                  control={<Radio />}
                  checked={selectedFilterColorValue === 'grey'}
                  onChange={(e) => {
                    setSelectedFilterColorValue(e.target.value)
                  }}
                />
              </FormGroup>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={5}
          style={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}
        >
          <div>
            <span style={{ color: '#ffffff', fontSize: '14px' }}>Período de </span>
            <Input
              style={{ color: '#ffffff' }}
              type="date"
              disableUnderline
              className={classes.inputPeriod}
              value={beginDate}
              onChange={(e) => {
                setBeginDate(e.target.value)
              }}
            />
            {' '}
            <span style={{ color: '#ffffff', fontSize: '14px' }}> até </span>
            <Input
              style={{ color: '#ffffff' }}
              type="date"
              disableUnderline
              className={classes.inputPeriod}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              style={{ marginLeft: '8px' }}
              onClick={() => {
                onFilterClick()
              }}
            >
              Filtrar
            </Fab>
          </div>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell align="left">CONTAINER</CustomTableCell>
              <CustomTableCell align="left">SITE</CustomTableCell>
              <CustomTableCell align="left">STATUS PROCESSO</CustomTableCell>
              <CustomTableCell align="left">STATUS 1º VEREDITO</CustomTableCell>
              <CustomTableCell align="left">DATA 1º VEREDITO</CustomTableCell>
              <CustomTableCell align="left">STATUS 2º VEREDITO</CustomTableCell>
              <CustomTableCell align="left">DATA 2º VEREDITO</CustomTableCell>
              <CustomTableCell align="left">STATUS 3º VEREDITO</CustomTableCell>
              <CustomTableCell align="left">DATA 3º VEREDITO</CustomTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map(row => (
              <TableRow
                className={classes.row}
                key={row.internal_id}
                style={{ fontFamily: 'Roboto Regular' }}
              >
                <CustomTableCell align="left">{row.object_identifier}</CustomTableCell>
                <CustomTableCell align="left">{row.scan.site}</CustomTableCell>
                <CustomTableCell align="left">
                  <ProcessStatusWithIcon status={row.internal_status} />
                </CustomTableCell>
                {renderOperations(row.operations)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data.length === 0 && searching === false ? (
          <Typography style={{ padding: '10px' }}>Nenhum dado encontrado!</Typography>
        ) : (
          ''
        )}
      </Paper>
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={pagination.totalItens}
        rowsPerPage={pagination.perPage}
        page={pagination.currentPage - 1}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
      />
      <Grid>
        {searching ? (
          <div style={{ margin: '10% 50%' }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          ''
        )}
      </Grid>
    </div>
  )
}

export default withStyles(styles)(ContainersList)
