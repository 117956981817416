/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Table,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  TablePagination,
  Fab,
  Radio,
} from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'

import { listOperations, listUsers } from 'services/loadAnalysisApi'

import styles from './styles'

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
  },
  body: {
    fontSize: 16,
    color: '#666666',
  },
}))(TableCell)

const StatusVeredictTxt = (props) => {
  const { veredict } = props
  let colorStatus = 'white'

  switch (veredict) {
    case 'No Suspect':
      colorStatus = 'green'
      break
    case 'With Suspect':
      colorStatus = 'red'
      break
    default:
      colorStatus = 'white'
      break
  }

  return (
    <span style={{ color: colorStatus }}>
      <FiberManualRecord />
      {' '}
    </span>
  )
}

const PercentTextPositive = (props) => {
  const { percent } = props

  if (parseInt(percent, 10) >= 50) {
    return (
      <span style={{ color: '#006400' }}>
        {percent}
%
      </span>
    )
  }
  return (
    <span style={{ color: 'red' }}>
      {percent}
%
    </span>
  )
}

const PercentTextNegative = (props) => {
  const { percent } = props

  if (parseInt(percent, 10) >= 50) {
    return (
      <span style={{ color: 'red' }}>
        {percent}
%
      </span>
    )
  }
  return (
    <span style={{ color: '#006400' }}>
      {percent}
%
    </span>
  )
}

const ConsultOperator = () => {
  const classes = styles()

  const apiKey = useSelector(state => state.auth.api_key)
  const userConfigData = useSelector(state => state.userConfig.data)

  const [data, setData] = useState([])
  const [searching, setSearching] = useState(false)
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState('')
  const [meta, setMeta] = useState(null)
  const [filterVeredict, setFilterVeredict] = useState('all')
  const [filterInconsistent, setFilterInconsistent] = useState(false)
  const [pagination, setPagination] = useState({
    perPage: 15,
    currentPage: 1,
    totalItens: 0,
  })

  const loadUsers = async () => {
    try {
      const response = await listUsers(apiKey, userConfigData.internal_id)

      setUsers(response.data)
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }
    }
  }

  const loadOperations = async (page = '1', selectUser = selectedUser) => {
    setData([])
    setSearching(true)
    setSelectedUser(selectUser)

    try {
      const response = await listOperations(
        apiKey,
        selectUser,
        page,
        filterVeredict,
        filterInconsistent
      )

      setData(response.data.operations)
      setPagination({
        perPage: parseInt(response.headers['x-per-page'], 10),
        currentPage: parseInt(response.headers['x-page'], 10),
        totalItens: parseInt(response.headers['x-total'], 10),
      })
      setSearching(false)
      setMeta(selectUser === '' ? null : response.data.meta)
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }
    }
  }

  useEffect(() => {
    loadUsers()
    loadOperations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = (e, page) => {
    loadOperations((page + 1).toString())
  }

  const sanitizeDate = (date) => {
    try {
      const dateTxt = moment(date).format('DD-MM-YYYY HH:mm')
      return dateTxt === 'Invalid date' ? '' : dateTxt
    } catch (e) {
      return ''
    }
  }

  return (
    <div className={classes.root}>

      <Grid container style={{ backgroundColor: '#666666', padding: '50px' }}>
        <Grid item xs={7}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <FormControl>
                <Typography
                  style={{
                    fontSize: '20px',
                    fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                    fontWeight: '700',
                    color: '#ffffff',
                  }}
                >
                  OPERADOR
                </Typography>
                <Select
                  style={{ color: '#ffffff' }}
                  value={selectedUser}
                  onChange={(e) => {
                    loadOperations(1, e.target.value)
                  }}
                >
                  <MenuItem value="" />
                  {users.map((row, id) => (
                    <MenuItem value={row.name} key={id.toString()}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </Grid>

        <Grid item xs={5}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                color: '#ffffff',
                fontSize: '14px',
                marginLeft: 20,
              }}
            >
              <span
                style={{
                  fontSize: '14px',
                  fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                  fontWeight: '700',
                }}
              >
                Status Veredito
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#ffffff',
                  fontSize: '14px',
                }}
              >
                <FormGroup row>
                  <FormControlLabel
                    value="all"
                    label={(
                      <span
                        style={{
                          fontSize: '14px',
                          fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                          color: 'white',
                        }}
                      >
                        Todos
                      </span>
)}
                    control={<Radio />}
                    checked={filterVeredict === 'all'}
                    onChange={(e) => {
                      setFilterVeredict(e.target.value)
                    }}
                  />
                  <FormControlLabel
                    value="ok"
                    label={(
                      <span
                        style={{
                          fontSize: '14px',
                          fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                          color: 'white',
                        }}
                      >
                        Ok
                      </span>
)}
                    control={<Radio />}
                    checked={filterVeredict === 'ok'}
                    onChange={(e) => {
                      setFilterVeredict(e.target.value)
                    }}
                  />
                  <FormControlLabel
                    value="suspect"
                    label={(
                      <span
                        style={{
                          fontSize: '14px',
                          fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                          color: 'white',
                        }}
                      >
                        Suspeito
                      </span>
)}
                    control={<Radio />}
                    checked={filterVeredict === 'suspect'}
                    onChange={(e) => {
                      setFilterVeredict(e.target.value)
                    }}
                  />
                </FormGroup>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                color: '#ffffff',
                fontSize: '14px',
                marginLeft: 20,
              }}
            >
              <span
                style={{
                  fontSize: '14px',
                  fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                  fontWeight: '700',
                }}
              >
                Inconsistências
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#ffffff',
                  fontSize: '14px',
                }}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={filterInconsistent}
                        onChange={() => {
                          setFilterInconsistent(!filterInconsistent)
                        }}
                        value="Sim"
                      />
)}
                    label={(
                      <span
                        style={{
                          fontSize: '14px',
                          fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                          color: 'white',
                        }}
                      >
                        Sim
                      </span>
)}
                  />
                </FormGroup>
                <Fab
                  variant="extended"
                  size="medium"
                  color="secondary"
                  style={{ marginLeft: '8px' }}
                  onClick={() => {
                    loadOperations()
                  }}
                >
                  Filtrar
                </Fab>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <Typography
                style={{
                  fontSize: '20px',
                  fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                  fontWeight: '700',
                  color: meta === null || typeof meta === 'undefined' ? 'lightgray' : 'white',
                }}
              >
                Ok:
                {' '}
                {meta === null || typeof meta === 'undefined' ? (
                  ''
                ) : (
                  <PercentTextPositive percent={meta.operations.percent.ok} />
                )}
              </Typography>
            </div>
            <div>
              <Typography
                style={{
                  fontSize: '20px',
                  fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                  fontWeight: '700',
                  color: meta === null || typeof meta === 'undefined' ? 'lightgray' : 'white',
                }}
              >
                Suspeito:
                {' '}
                {meta === null || typeof meta === 'undefined' ? (
                  ''
                ) : (
                  <PercentTextNegative percent={meta.operations.percent.suspect} />
                )}
              </Typography>
            </div>
            <div>
              <Typography
                style={{
                  fontSize: '20px',
                  fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                  fontWeight: '700',
                  color: meta === null || typeof meta === 'undefined' ? 'lightgray' : 'white',
                }}
              >
                Divergente:
                {' '}
                {meta === null || typeof meta === 'undefined' ? (
                  ''
                ) : (
                  <PercentTextNegative percent={meta.operations.percent.divergent} />
                )}
              </Typography>
            </div>
            <div>
              <Typography
                style={{
                  fontSize: '20px',
                  fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                  fontWeight: '700',
                  color: meta === null || typeof meta === 'undefined' ? 'lightgray' : 'white',
                }}
              >
                Coincidente:
                {' '}
                {meta === null || typeof meta === 'undefined' ? (
                  ''
                ) : (
                  <PercentTextPositive percent={meta.operations.percent.tie_breaker_coincident} />
                )}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell align="right">OPERADOR</CustomTableCell>
              <CustomTableCell align="center">DATA</CustomTableCell>
              <CustomTableCell align="center">CONTAINER</CustomTableCell>
              <CustomTableCell align="center">SITE</CustomTableCell>
              <CustomTableCell align="right">VEREDITO OPERADOR</CustomTableCell>
              <CustomTableCell align="right">OUTRO VEREDITO</CustomTableCell>
              <CustomTableCell align="right">VEREDITO SUPERVISOR</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <TableRow className={classes.row} key={row.internal_id}>
                <CustomTableCell align="right">{row.user_name}</CustomTableCell>
                <CustomTableCell align="right">{sanitizeDate(row.end_date)}</CustomTableCell>
                <CustomTableCell align="right">{row.object_identifier}</CustomTableCell>
                <CustomTableCell align="right">{row.site}</CustomTableCell>
                <CustomTableCell align="center">
                  <StatusVeredictTxt veredict={row.veredict} />
                </CustomTableCell>
                <CustomTableCell align="center">
                  <StatusVeredictTxt
                    veredict={
                      typeof row.operations[0] === 'undefined' ? '' : row.operations[0].veredict
                    }
                  />
                </CustomTableCell>
                <CustomTableCell align="center">
                  <StatusVeredictTxt
                    veredict={
                      typeof row.operations[1] === 'undefined' ? '' : row.operations[1].veredict
                    }
                  />
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data.length === 0 && searching === false ? (
          <Typography style={{ padding: '10px' }}>Nenhum dado encontrado!</Typography>
        ) : (
          ''
        )}
      </Paper>
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={pagination.totalItens}
        rowsPerPage={pagination.perPage}
        page={pagination.currentPage - 1}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
      />
      <Grid>
        {searching ? (
          <div style={{ margin: '10% 50%' }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          ''
        )}
      </Grid>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ConsultOperator)
