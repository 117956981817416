import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/browser'
import theme from 'helpers/theme'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { store, persistor } from './redux/store'

import App from './App'

if (process.env.NODE_ENV === 'production') {
  Sentry.onLoad(() => {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIROMENT,
    })
  })
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading="null" persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer />
        <App />
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept()
}
