import red from '@material-ui/core/colors/red'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#223963',
      hover: '#1666ae',
      bg: '#FFF',
      dark: '#192131',
      darker: '#070F20',
      light: '#9B9FB0',
      lighter: '#FFF',
    },
    secondary: {
      main: '#1666ae',
      hover: '#223963',
    },
    third: {
      main: '#00AC00',
      hover: '#019101',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: "'Barlow Condensed', sans-serif",
  },
  report: {
    fontSize: 18,
    fontSizeInPrint: 12,
    fontSizeTitle: 14,
    marginFieldInPrint: 0,
  },
})

export default theme
