import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Typography, Button, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { recoverPassword } from 'redux/auth/auth.actions'
import { Link } from 'react-router-dom'
import { recoverPasswd } from 'services/apiAuth'
import Recaptcha from 'react-recaptcha'
import styles from './styles'

class Index extends React.PureComponent {
  state = {
    username_email: '',
    loading: false,
    error: false,
    isVerified: false,
    recaptchaToken: null,
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleSubmit = async () => {
    await this.setState({ loading: true, error: false })
    try {
      const formData = new FormData()
      formData.append('username_email', this.state.username_email)
      formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
      formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)
      formData.append('recaptcha_token', this.state.recaptchaToken)

      const res = await recoverPasswd(formData)
      if (res.status === 200) {
        await this.setState({ loading: false })

        this.props.recoverPassword(res.data.destination)

        this.props.history.push({
          pathname: '/confirm-recover-password',
        })
      }
    } catch (error) {
      await this.setState({ loading: false, isVerified: false, recaptchaToken: null })
    }
  }

  verifyCaptcha = response => {
    if (response) {
      this.setState({
        isVerified: true,
        recaptchaToken: response,
      })
    }
  }

  render() {
    const { classes } = this.props
    const { loading, error, isVerified } = this.state
    return (
      <div className={classes.root}>
        <div className={classes.sidebar}>
          <img src="assets/images/logos/ebco.png" alt="Logo" className={classes.logo} />
          <div style={{ marginBottom: 50 }}>
            <h1 style={{ fontSize: 50 }}>Recuperar Senha</h1>
            <p style={{ fontSize: 18 }}>Recupere sua senha perdida ou esquecida!</p>
          </div>
        </div>
        <div className={classes.main}>
          <div>
            <h1 className={classes.titlePage}>
              Insira seu email ou nome de usuário:
            </h1>
            <p>Enviaremos para o email cadastrado um código que deverá ser utilizado para recuperar a senha.</p>
          </div>

          <TextField
            id="email"
            label="Nome de Usuário ou E-mail"
            className={classes.textField}
            value={this.state.username_email}
            onChange={this.handleChange('username_email')}
            type="email"
            required={true}
          />
          {error ? (
            <p>
              <strong style={{ color: 'red', fontSize: 16 }}>Usuário não encontrado nesse aplicativo.</strong>
            </p>
          ) : null}

          <div style={{ textAlign: 'center', width: 300 }}>
            {!isVerified ? (
              <Recaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                render="explicit"
                onloadCallback={() => {}}
                verifyCallback={this.verifyCaptcha}
                hl="pt-BR"
              />
            ) : (
              <React.Fragment>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleSubmit}
                  disabled={!!loading}
                >
                  {loading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{
                        width: 25,
                        height: 25,
                      }}
                    />
                  ) : (
                    'Recuperar'
                  )}
                </Button>
              </React.Fragment>
            )}
            <Typography>
              Lembrei minha senha.
              {' '}
              <Link to="/" className={classes.link}>
                Entrar
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authsReducer: state.auth,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      recoverPassword,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Index))
