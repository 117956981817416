import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  inputPeriod: {
    border: '1px solid white',
    borderRadius: '23px',
    width: '150px',
    textAlign: 'center',
  },
  titleCardWhite: {
    fontSize: '24px',
    fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
    fontWeight: '700',
    color: '#ffffff',
  },
  holla: {
    color: 'white',
  },
}))

export default styles
