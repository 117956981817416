import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles, useTheme } from '@material-ui/core/styles'
// import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  AppBar,
  Toolbar,
  Typography,
  // InputBase,
  MenuItem,
  useMediaQuery,
  IconButton,
  Drawer,
  Menu,
} from '@material-ui/core'

import {
  ArrowDropDownCircle,
  Assessment as AssessmentIcon,
  CheckCircle,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Dns as DnsIcon,
  ExitToApp as ExitToAppIcon,
  InsertEmoticon,
  Menu as MenuIcon,
} from '@material-ui/icons'

import { logout } from 'redux/auth/auth.actions'
import {
  userConfigClear,
} from 'redux/userConfig/actions'

import styles from './styles'

const MainNavbar = () => {
  const classes = styles()
  const [isPageInFocus, setIsPageInFocus] = React.useState(null)
  const [anchorElConsult, setAnchorElConsult] = React.useState(null)
  const dispatch = useDispatch()
  const theme = useTheme()

  const name = useSelector(state => state.auth.name)
  const userConfigData = useSelector(state => state.userConfig.data)

  const [openDrawer, setOpenDrawer] = React.useState(false)

  React.useEffect(() => {
    const url = window.location.href
    if (url.includes('containers_list')) {
      setIsPageInFocus('list')
    } else if (url.includes('analyze_container')) {
      setIsPageInFocus('analyze')
    } else if (url.includes('consult')) {
      setIsPageInFocus('consult')
    } else if (url.includes('dashboard')) {
      setIsPageInFocus('dashboard')
    }
  }, [])

  const handleMenuConsultOpen = (event) => {
    setAnchorElConsult(event.currentTarget)
  }

  const handleMenuConsultClose = () => {
    setAnchorElConsult(null)
  }

  function handleDrawerOpen() {
    setOpenDrawer(true)
  }

  function handleDrawerClose() {
    setOpenDrawer(false)
  }

  const menuItens = () => (
    <ul className={classes.listNav}>
      { userConfigData.role === 'supervisor' && (
        <React.Fragment>
          <li
            className={classnames(classes.listNavItem, isPageInFocus === 'dashboard' ? classes.listNavItemHover : '')}
          >
            <a className={classes.listNavItemAnchor} href="/dashboard">
              <Typography color="inherit">DASHBOARD</Typography>
              <div className={classes.listNavItemSep} />
              <AssessmentIcon />
            </a>
          </li>

          <li
            className={classnames(classes.listNavItem, isPageInFocus === 'list' ? classes.listNavItemHover : '')}
          >
            <a className={classes.listNavItemAnchor} href="/containers_list">
              <Typography color="inherit">LISTAR CONTAINERS</Typography>
              <div className={classes.listNavItemSep} />
              <DnsIcon />
            </a>
          </li>

          <li
            className={classnames(classes.listNavItem, isPageInFocus === 'consult' ? classes.listNavItemHover : '')}
            onClick={handleMenuConsultOpen}
          >
            <a
              style={{ pointerEvents: 'none' }}
              className={classes.listNavItemAnchor}
              href="/"
            >
              <Typography color="inherit">CONSULTAR</Typography>
              <div className={classes.listNavItemSep} />
              <ArrowDropDownCircle onClick={handleMenuConsultOpen} />
            </a>
          </li>
        </React.Fragment>
      )}

      <li
        className={classnames(classes.listNavItem, isPageInFocus === 'analyze' ? classes.listNavItemHover : '')}
      >
        <a className={classes.listNavItemAnchor} href="/analyze_container">
          <Typography color="inherit">ANALISAR</Typography>
          <div className={classes.listNavItemSep} />
          <CheckCircle />
        </a>
      </li>

    </ul>
  )

  const sectionUser = () => (
    <div className={classes.sectionUser}>
      <ul className={classes.listNav}>
        <li className={classes.listNavItemUsername}>
          <InsertEmoticon />
          <Typography color="inherit" className={classes.menuItem}>{name.toUpperCase()}</Typography>
        </li>
        <li className={classes.listNavItem}>
          <ExitToAppIcon
            onClick={() => {
              dispatch(userConfigClear())
              dispatch(logout())
            }}
          />
          <Typography color="inherit" className={classes.logolf}>SAIR</Typography>
        </li>
      </ul>
    </div>
  )

  const renderMenuConsult = () => (
    <Menu
      anchorEl={anchorElConsult}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorElConsult)}
      onClose={handleMenuConsultClose}
    >
      <MenuItem onClick={handleMenuConsultClose}>
        <a style={{ textDecoration: 'none' }} href="/consult_operator">
          Operador
        </a>
      </MenuItem>
      <MenuItem onClick={handleMenuConsultClose}>
        <a style={{ textDecoration: 'none' }} href="/consult_container">
          Container
        </a>
      </MenuItem>
    </Menu>
  )

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.appbar}
      >
        <Toolbar>
          <div
            className={classes.toolbar}
          >
            <div className={classes.sectionLogo}>
              {useMediaQuery('(max-width: 1280px)') && (
                <IconButton
                  color="inherit"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <div className={classes.logoContainer}>
                <div className={classes.logoContainerImg}>
                  <img
                    className={classes.logoImg}
                    src="assets/images/logos/ebco.png"
                    alt="logo"
                  />
                </div>
                <div className={classes.containerTitle}>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    color="inherit"
                    noWrap
                  >
                    Poseidon
                  </Typography>
                  <span className={classes.version}>
                    {`version ${process.env.REACT_APP_PROJECT_VERSION}`}
                  </span>
                </div>
              </div>
            </div>

            {/*<div className={classes.sectionDesktop}>
              <ul className={classes.listNav}>
                <li
                  className={classnames(classes.listNavItem, isPageInFocus === 'dashboard' ? classes.listNavItemHover : '')}
                >
                  <a className={classes.listNavItemAnchor} href="/dashboard">
                    <Typography color="inherit">DASHBOARD</Typography>
                    <div className={classes.listNavItemSep} />
                    <AssessmentIcon />
                  </a>
                </li>

                <li
                  className={classnames(classes.listNavItem, isPageInFocus === 'list' ? classes.listNavItemHover : '')}
                >
                  <a className={classes.listNavItemAnchor} href="/containers_list">
                    <Typography color="inherit">LISTAR CONTAINERS</Typography>
                    <div className={classes.listNavItemSep} />
                    <DnsIcon />
                  </a>
                </li>

                <li
                  className={classnames(classes.listNavItem, isPageInFocus === 'analyze' ? classes.listNavItemHover : '')}
                >
                  <a className={classes.listNavItemAnchor} href="/analyze_container">
                    <Typography color="inherit">ANALISAR</Typography>
                    <div className={classes.listNavItemSep} />
                    <CheckCircle />
                  </a>
                </li>

                <li
                  className={classnames(classes.listNavItem, isPageInFocus === 'consult' ? classes.listNavItemHover : '')}
                  onClick={handleMenuConsultOpen}
                >
                  <a
                    style={{ pointerEvents: 'none' }}
                    className={classes.listNavItemAnchor}
                    href="/"
                  >
                    <Typography color="inherit">CONSULTAR</Typography>
                    <div className={classes.listNavItemSep} />
                    <ArrowDropDownCircle onClick={handleMenuConsultOpen} />
                  </a>
                </li>
              </ul>
            </div>*/}

            <div
              className={classes.menubar}
            >
              <div className={classes.sectionDesktop}>
                {useMediaQuery('(min-width: 1280px)') && menuItens()}
              </div>

              {useMediaQuery('(min-width: 1280px)') && sectionUser()}

            </div>
          </div>
        </Toolbar>
        <Drawer
          className={classes.drawer}
          open={openDrawer}
          onClose={handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          {useMediaQuery('(max-width: 1280px)') && menuItens()}
          {useMediaQuery('(max-width: 1280px)') && sectionUser()}
        </Drawer>
      </AppBar>
      {renderMenuConsult()}
    </div>
  )
}

MainNavbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MainNavbar)
