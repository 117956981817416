import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'

import Login from 'pages/Login'
import ConfirmRecoverPassword from 'pages/ConfirmRecoverPassword'
import RecoverPassword from 'pages/RecoverPassword'
import ChangePassword from 'pages/ChangePassword'

import Dashboard from 'pages/Dashboard/index'
import ContainersList from 'pages/ContainersList/index'
import AnalyzeContainer from 'pages/AnalyzeContainer/index'
import ConsultOperator from 'pages/ConsultOperator/index'
import ConsultContainer from 'pages/ConsultContainer/index'

import PrivateRoute from './PrivateRoute'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/change-password" exact component={ChangePassword} />
      <Route path="/confirm-recover-password" exact component={ConfirmRecoverPassword} />
      <Route path="/recover-password" exact component={RecoverPassword} />

      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/containers_list" component={ContainersList} />
      <PrivateRoute path="/analyze_container" component={AnalyzeContainer} />
      <PrivateRoute path="/consult_operator" component={ConsultOperator} />
      <PrivateRoute path="/consult_container" component={ConsultContainer} />

      <Route path="*" component={() => <h1>Esta página não existe!</h1>} />
    </Switch>
  </BrowserRouter>
)

export default Routes
