export default (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'col',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    sidebar: {
        backgroundColor: theme.palette.primary.main,
        height: '100%',
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 40,
        color: '#FFF',
    },
    logo: {
      width: 300,
      backgroundColor: '#fff',
      padding: 10,
      borderRadius: 10,
    },
    main: {
        height: '100%',
        width: '60%',
        padding: '60px 100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    textField: {
        width: 400,
        marginBottom: 30,
    },
    button: {
        width: 300,
        height: '50px',
        borderRadius: 30,
        marginTop: '50px',
        marginBottom: 20,
        '&:hover':{
          backgroundColor: theme.palette.primary.hover,
        },
    },
    titlePage: {
      fontSize: 30,
      color: theme.palette.primary.main,
    },
})
