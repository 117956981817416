import _ from 'lodash'
import axios from './axiosCustom'

const HOST = process.env.REACT_APP_POSEIDON_ANALYSIS_HOST

const headers = (apiKey) => {
  const objHeaders = {
    'Content-type': 'application/json',
    'x-api-key': apiKey,
  }

  return objHeaders
}

export const vehiclesDashboard = (apiKey, beginDate, endDate) => {
  let queryString = `begin_date=${beginDate}`
  queryString += `&end_date=${endDate}`

  return axios({
    method: 'GET',
    url: `${HOST}/vehicles?${queryString}`,
    headers: headers(apiKey),
  })
}

export const listVehicles = (apiKey, beginDate, endDate, page = '1', status = 'all') => {
  let queryString = `begin_date=${beginDate}`
  queryString += `&end_date=${endDate}`
  queryString += `&page=${page}`
  queryString += status !== 'all' ? `&internal_status=${status}` : ''

  return axios({
    method: 'GET',
    url: `${HOST}/vehicles/detaileds?${queryString}`,
    headers: headers(apiKey),
  })
}

export const listOperations = (
  apiKey,
  userName = '',
  page = '1',
  filterVeredict = 'all',
  filterInconsistent = false,
) => {
  let queryString = 'query_type=operation'
  queryString += `&page=${page}`
  queryString += filterVeredict !== 'all' ? `&veredict=${filterVeredict}` : ''
  queryString += filterInconsistent ? '&unconscious=true' : ''
  queryString += !_.isEmpty(userName) ? `&user=${userName}` : ''

  return axios({
    method: 'GET',
    url: `${HOST}/query?${queryString}`,
    headers: headers(apiKey),
  })
}

export const listUsers = (apiKey, supervisorInternalId) => {
  const queryString = `supervisor_internal_id=${supervisorInternalId}`

  return axios({
    method: 'GET',
    url: `${HOST}/users/list?${queryString}`,
    headers: headers(apiKey),
  })
}

export const listContainers = (apiKey, container = '', page = '1') => {
  let queryString = 'query_type=vehicle'
  queryString += '&object_type=container'
  queryString += `&page=${page}`
  queryString += !_.isEmpty(container) ? `&object_identifier=${container}` : ''

  return axios({
    method: 'GET',
    url: `${HOST}/query?${queryString}`,
    headers: headers(apiKey),
  })
}

export const listSites = (apiKey, site = '', page = '1') => {
  let queryString = 'query_type=vehicle'
  queryString += '&object_type=container'
  queryString += `&page=${page}`
  queryString += !_.isEmpty(site) ? `&site=${site}` : ''

  return axios({
    method: 'GET',
    url: `${HOST}/query?${queryString}`,
    headers: headers(apiKey),
  })
}

export const getUserConfig = (apiKey, userId) => {
  const queryString = `user_id=${userId}`

  return axios({
    method: 'GET',
    url: `${HOST}/users?${queryString}`,
    headers: headers(apiKey),
  })
}
