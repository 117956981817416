import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'
import jwtDecode from 'jwt-decode'
import Recaptcha from 'react-recaptcha'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TextField, Button, CircularProgress, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { successLogin, changePassword } from 'redux/auth/auth.actions'
import { login } from 'services/apiAuth'
import {
  fetchUserConfig,
} from 'redux/userConfig/actions'

import styles from './styles'

const Login = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()

  const accessToken = useSelector(state => state.auth.access_token)
  const userConfigData = useSelector(state => state.userConfig.data)
  const userConfigFail = useSelector(state => state.userConfig.fail)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  const isLogged = token => {
    if (_.isEmpty(token)) return false

    const decoded = jwtDecode(token)
    const current_time = Date.now() / 1000
    return decoded.exp > current_time
  }

  useEffect(() => {
    if (isLogged(accessToken)) {
      dispatch(fetchUserConfig())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!_.isEmpty(accessToken)) {
      if (_.isEmpty(userConfigData.role) || userConfigFail) {
        toast.error('Houve um problema no login, contate o adminstrador')
      } else if (userConfigData.role === 'supervisor') {
        history.push('/dashboard')
      } else {
        history.push('/analyze_container')
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfigData.role, userConfigFail])

  const handleSubmit = async (argEmail, argPassword) => {
    setLoading(true)

    try {
      const formData = new FormData()
      formData.append('username', argEmail)
      formData.append('password', argPassword)
      formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
      formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)
      formData.append('recaptcha_token', recaptchaToken)

      const res = await login(formData)
      if (res.status === 302) {
        dispatch(changePassword({
          email: email,
          changePasswordSession: res.data.session,
        }))
        return history.push('/change-password')
      }

      const userdata = res.data
      if (userdata) {
        dispatch(successLogin({ ...userdata }))
        dispatch(fetchUserConfig())
      }
      return false
    } catch (err) {
      setLoading(false)
      setIsVerified(false)
      setRecaptchaToken(null)

      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Usuário ou senha inválidos'
      return toast.error(messageError)
    }
  }

  const onEnterPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
      return handleSubmit(email, password)
    }
    return false
  }

  const verifyCaptcha = response => {
    if (response) {
      setIsVerified(true)
      setRecaptchaToken(response)
    }
  }

  return (
    <div className={classes.root}>
      <aside className={classes.aside}>
        <h1>
          <img src="assets/images/logos/ebco.png" alt="Logo" className={classes.logo} />
        </h1>
      </aside>
      <main className={classes.main}>
        <div className={classes.main}>
          <h1 className={classes.titlePage}>Login</h1>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <form>
              <TextField
                id="email"
                label="E-mail"
                className={classes.textField}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                id="password"
                label="Senha"
                className={classes.textField}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                onKeyDown={onEnterPress}
                autoComplete="off"
              />
            </form>
            <Typography>
              <Link to="/recover-password">Esqueci a senha</Link>
            </Typography>
          </div>

          <div style={{ textAlign: 'center', width: 300 }}>
            {!isVerified ? (
              <Recaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                render="explicit"
                onloadCallback={() => {}}
                verifyCallback={verifyCaptcha}
                hl="pt-BR"
              />
            ) : (
              <React.Fragment>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => handleSubmit(email, password)}
                  disabled={!!loading}
                >
                  {loading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{
                        width: 25,
                        height: 25,
                      }}
                    />
                  ) : (
                    'Acessar'
                  )}
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </main>
    </div>
  )
}

export default withStyles(styles)(Login)
