import saveAs from 'file-saver'
import { reqS3PresignerUrl } from 'services/apiFilesDownloader'

export const s3PresignerUrlOpen = async (docUrl) => {
  if (!docUrl) return

  try {
    const response = await reqS3PresignerUrl(docUrl)

    window.open(response.data.url, '_blank')
  } catch (err) {
    if (err.response) {
      console.error(err.response)
    } else if (err.request) {
      console.error(err.request)
    } else {
      console.error(err.message)
    }
  }
}

export const s3PresignerUrlSaveFile = async (url) => {
  try {
    const responseUrl = await reqS3PresignerUrl(url)
    saveAs(responseUrl.data.url)
  } catch {
    return Promise.reject('Houve um problema ao baixar o arquivo')
  }
}

export const s3PresignerUrl = async (url) => {
  try {
    const responseUrl = await reqS3PresignerUrl(url)
    return responseUrl.data.url
  } catch {
    return Promise.reject('Houve um problema ao baixar o arquivo')
  }
}

export const isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response
}
