import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
  },
  aside: {
    display: 'flex',
    flexDirection: 'row',
    background: theme.palette.primary.main,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    height: '100%',
    width: '40%',
    justifyContent: 'space-between',
    padding: 40,
    color: '#FFF',
  },
  logo: {
    width: 300,
    backgroundColor: '#fff',
    padding: 10,
    borderRadius: 10,
  },
  title: {
    marginBottom: 50,
  },
  main: {
    height: '100%',
    width: '60%',
    padding: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textField: {
    width: 400,
    marginBottom: 30,
  },
  button: {
    width: 300,
    height: '50px',
    borderRadius: 30,
    marginTop: '50px',
    marginBottom: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  progress: {
    margin: theme.spacing(1),
  },
  titlePage: {
    fontSize: 30,
    color: theme.palette.primary.main,
  },
}))

export default styles
