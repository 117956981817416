import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  titleCardWhite: {
    fontSize: '24px',
    fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
    fontWeight: '700',
    color: '#ffffff',
  },
  imgContainerPreview: {
    border: '1px solid #000',
    cursor: 'pointer',
    height: 180,
  },
}))

export default styles
