/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import {
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Fab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import { Attachment, CloudDownload, Add } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { s3PresignerUrlSaveFile, s3PresignerUrl } from 'helpers/functions'

import {
  containerSort,
  createOperation,
  putEmptyContainer,
  putSuspectCoordinates,
  putSuspectType,
  createSuspect,
  finalizeOperation,
  listSuspects,
  // deleteSuspect,
} from 'services/createAnalysisApi'
import constants from 'helpers/constants/constants'

import styles from './styles'

let objReactCropComponentRef = null

const AnalyzeContainer = () => {
  const classes = styles()

  const apiKey = useSelector(state => state.auth.api_key)
  const userConfigData = useSelector(state => state.userConfig.data)

  const [containerSorted, setContainerSorted] = useState(null)
  const [currentImgFile, setCurrentImgFile] = useState(null)
  const [currentSuspectId, setCurrentSuspectId] = useState(null)
  const [currentSuspects, setCurrentSuspects] = useState([])
  const [disableAddSuspect, setDisableAddSuspect] = useState(true)
  const [disabledEmptyContainerRadiobox, setDisabledEmptyContainerRadiobox] = useState(false)
  const [displayMarkSuspectImgs, setDisplayMarkSuspectImgs] = useState('none')
  const [isDowloaded, setIsDowloaded] = useState(false)
  const [isEmptyContainer, setIsEmptyContainer] = useState(null)
  const [modalShow, setModalShow] = useState(false)
  const [operationCreated, setOperationCreated] = useState(null)
  const [raffle, setRaffle] = useState(true)
  const [selectedSuspicionsType, setSelectedSuspicionsType] = useState('')
  const [suspicionsComment, setSuspicionsComment] = useState('')
  // cosnt [suspectsAddedCount, setSuspectsAddedCount] = useState(0)
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const sortContainer = async () => {
      const thirdVeredict = userConfigData.role === constants.USER_ROLE.supervisor
      try {
        // Sorteio do container
        const containerSortResponse = await containerSort(apiKey, userConfigData.internal_id, thirdVeredict)
        if (_.get(containerSortResponse, 'data.msg_errors.0.msg')) {
          alert(_.get(containerSortResponse, 'data.msg_errors.0.msg'))
          return
        }

        setContainerSorted(containerSortResponse.data)
        setRaffle(false)
      } catch (err) {
        if (err.response) {
          alert(_.get(err, 'response.data.msg_errors.0.msg'))
        } else if (err.request) {
          alert(err.request)
        } else {
          alert(err.message)
        }
      }
    }

    sortContainer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const modalClose = () => {
    setModalShow(false)
  }

  const finalizesOperation = async () => {
    try {
      let responseList = null

      const dataFinalize = {
        user_internal_id: userConfigData.internal_id,
        empty_container: isEmptyContainer,
        operation_id: operationCreated.internal_id,
      }

      if (!isEmptyContainer) {
        responseList = await listSuspects(apiKey, operationCreated.internal_id)
        dataFinalize.suspicions = responseList.data
      }

      await finalizeOperation(apiKey, dataFinalize)
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }
    }
  }

  const saveEmptyOperation = async () => {
    try {
      await putEmptyContainer(apiKey, operationCreated.internal_id, isEmptyContainer)

      if (isEmptyContainer) {
        await finalizesOperation()
        window.location.reload()
        return
      }

      setDisableAddSuspect(false)
      setDisplayMarkSuspectImgs('')
      setDisabledEmptyContainerRadiobox(true)
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }

      window.location.reload()
    }
  }

  const handleAddSuspect = async () => {
    try {
      const createdSuspect = await createSuspect(apiKey, operationCreated.internal_id)
      setCurrentSuspectId(createdSuspect.data.internal_id)
      //setSuspectsAddedCount(suspectsAddedCount + 1)
      setDisableAddSuspect(true)
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }
    }
  }

  const updateSuspect = async () => {
    const currentImgMarkWidth = objReactCropComponentRef.clientWidth
    const currentImgMarkHeight = objReactCropComponentRef.clientHeight
    if (
      (crop.x === 0 && crop.y === 0 && crop.width === 0 && crop.height === 0)
      || selectedSuspicionsType === ''
      || suspicionsComment === ''
    ) {
      return alert('Todas as informações devem ser preenchidas (marcação da suspeita, tipo e comentário)')
    }
    try {
      await putSuspectCoordinates(
        apiKey,
        operationCreated.internal_id,
        currentSuspectId,
        crop,
        currentImgFile.internal_id,
        currentImgMarkWidth.toString(),
        currentImgMarkHeight.toString(),
      )

      await putSuspectType(
        apiKey,
        operationCreated.internal_id,
        currentSuspectId,
        selectedSuspicionsType,
        suspicionsComment,
      )

      const responseList = await listSuspects(
        apiKey,
        operationCreated.internal_id,
        currentImgFile.internal_id,
      )

      setCurrentSuspectId(null)
      setDisableAddSuspect(false)
      setSelectedSuspicionsType('')
      setSuspicionsComment('')
      setCurrentSuspects(responseList.data)
      setCrop({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      })
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }
    }
    return ''
  }

  const handleFinalizeOperation = async () => {
    try {
      await finalizesOperation()
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }

      return
    }

    window.location.reload()
  }

  const handleDownloadXray = async () => {
    await s3PresignerUrlSaveFile(containerSorted.files.filter(obj => obj.type === 'x-ray')[0].url)

    if (raffle === false) {
      let response = null
      try {
        // Criar operacao
        response = await createOperation(apiKey, {
          vehicle_id: containerSorted.internal_id,
          user_internal_id: userConfigData.internal_id,
        })
      } catch (err) {
        if (err.response) {
          alert(_.get(err, 'response.data.msg_errors.0.msg'))
        } else if (err.request) {
          alert(err.request)
        } else {
          alert(err.message)
        }

        return
      }

      try {
        // generate assign urls
        containerSorted.files = await Promise.all(_.map(containerSorted.files, async (f) => {
          if (f.type === 'x-ray') return f
          return { ...f, urlSigned: await s3PresignerUrl(f.url) }
        }))
      } catch (err) {
        if (err.response) {
          alert(_.get(err, 'response.data.msg_errors.0.msg'))
        } else if (err.request) {
          alert(err.request)
        } else {
          alert(err.message)
        }

        return
      }

      setOperationCreated(response.data)
      if (_.get(response, 'data.empty_container') !== null) {
        setIsEmptyContainer(_.get(response, 'data.empty_container'))
        setDisabledEmptyContainerRadiobox(true)
        setDisableAddSuspect(false)
        setDisplayMarkSuspectImgs('')
      }
      setIsDowloaded(true)
      setContainerSorted(containerSorted)
    }
  }

  const handleOpenModalImage = async (file) => {
    // renew url signed
    let urlSigned = null
    try {
      urlSigned = await s3PresignerUrl(file.url)
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }

      return
    }

    let responseSuspectList = null
    try {
      responseSuspectList = await listSuspects(
        apiKey,
        operationCreated.internal_id,
        file.internal_id,
      )
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }

      return
    }

    setCurrentImgFile({ ...file, urlSigned: urlSigned })
    setModalShow(true)
    setCurrentSuspects(responseSuspectList.data)
  }

  const drawCanvas = () => {
    const c = document.getElementById('canvas')
    let differenceImgWidth = 0
    let differenceImgHeight = 0
    let percentDifferenceWidth = 0
    let percentDifferenceHeight = 0

    if (c != null && objReactCropComponentRef != null) {
      c.width = objReactCropComponentRef.clientWidth
      c.height = objReactCropComponentRef.clientHeight

      const ctx = c.getContext('2d')
      ctx.fillStyle = 'red'
      ctx.font = '8pt sans-serif'
      ctx.strokeStyle = 'red'
      currentSuspects.forEach((mark, id) => {
        differenceImgWidth = objReactCropComponentRef.clientWidth - parseFloat(mark.width)
        differenceImgHeight = objReactCropComponentRef.clientHeight - parseFloat(mark.height)
        percentDifferenceWidth = (100 * differenceImgWidth) / parseFloat(mark.width)
        percentDifferenceHeight = (100 * differenceImgHeight) / parseFloat(mark.height)

        ctx.rect(
          parseFloat(mark.right) + (parseFloat(mark.right) / 100) * percentDifferenceWidth,
          parseFloat(mark.top) + (parseFloat(mark.top) / 100) * percentDifferenceHeight,
          parseFloat(mark.left) + (parseFloat(mark.left) / 100) * percentDifferenceWidth,
          parseFloat(mark.bottom) + (parseFloat(mark.bottom) / 100) * percentDifferenceHeight,
        )
        ctx.fillText(
          (id + 1).toString(),
          parseFloat(mark.right) + 2 + (parseFloat(mark.right) / 100) * percentDifferenceWidth,
          parseFloat(mark.top) + 10 + (parseFloat(mark.top) / 100) * percentDifferenceHeight,
        )
        ctx.stroke()
      })
    }
  }

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={modalShow}
        onClose={modalClose}
        onEntered={drawCanvas}
      >
        <DialogTitle id="responsive-dialog-title">Marcar Suspeita</DialogTitle>
        <DialogContent>
          <Grid container style={{ padding: '0 50px' }} spacing={3}>
            <Grid item xs={8}>
              <ReactCrop
                // ref={ref => (objReactCropComponentRef = ref)}
                src={
                  currentImgFile != null
                    ? currentImgFile.urlSigned
                    : ''
                }
                onChange={(cropObj) => setCrop(cropObj)}
                crop={crop}
                style={{ backgroundColor: 'transparent' }}
                imageStyle={{ border: '2px solid #000' }}
                onImageLoaded={(image) => { objReactCropComponentRef = image }}
                disabled={!disableAddSuspect}
              >
                <canvas
                  id="canvas"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    display: disableAddSuspect ? 'none' : '',
                  }}
                />
              </ReactCrop>
              {drawCanvas()}
            </Grid>
            <Grid item xs={4}>
              <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                  <Fab
                    id="btn_add_suspect"
                    size="medium"
                    color="secondary"
                    aria-label="Add"
                    disabled={disableAddSuspect}
                    style={{ margin: 5 }}
                    align="rigth"
                    onClick={handleAddSuspect}
                  >
                    <Add />
                  </Fab>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextField
                    // disableUnderline
                    label="Tipo"
                    disabled={!disableAddSuspect}
                    value={selectedSuspicionsType}
                    onChange={(e) => {
                      setSelectedSuspicionsType(e.target.value)
                    }}
                    style={{ width: '120px' }}
                  />
                  <TextField
                    // disableUnderline
                    label="Descrição"
                    disabled={!disableAddSuspect}
                    value={suspicionsComment}
                    onChange={(e) => {
                      setSuspicionsComment(e.target.value)
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                    padding: '5px',
                  }}
                >
                  <Fab
                    id="btn_update_suspect"
                    variant="extended"
                    size="medium"
                    color="secondary"
                    style={{ marginLeft: '8px' }}
                    disabled={!disableAddSuspect}
                    onClick={updateSuspect}
                  >
                    Salvar Suspeição
                  </Fab>
                </div>
              </div>
              <div style={{ marginTop: '20px' }}>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Id</TableCell>
                        <TableCell align="center">Tipo</TableCell>
                        <TableCell align="center">Comentário</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentSuspects.map((row, id) => (
                        <TableRow className={classes.row} key={row.internal_id}>
                          <TableCell align="center">{(id + 1).toString()}</TableCell>
                          <TableCell align="center">{row.type}</TableCell>
                          <TableCell align="center">{row.comment}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={modalClose} color="primary" id="btn_close_modal">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container style={{ backgroundColor: '#666666', padding: '50px' }}>
        <Grid item xs={12}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#ffffff',
                fontSize: '14px',
              }}
            >
              <Attachment />
              {' '}
              <span>Arquivo</span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#ffffff',
                fontSize: '14px',
              }}
            >
              {raffle ? (
                <CircularProgress color="secondary" />
              ) : (
                _.isEmpty(containerSorted)
                  ? <strong>Não há containers para Analisar</strong>
                  : (
                    <Button
                      id="btn_download_xray"
                      onClick={handleDownloadXray}
                      variant="contained"
                      color="secondary"
                      disabled={isDowloaded}
                    >
                      <span>Baixar Arquivo Raio-X</span>
                      <CloudDownload style={{ marginLeft: 8 }} />
                    </Button>
                  )
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '30px', padding: '0 50px' }} spacing={3}>
        <Grid container style={{ marginTop: '30px', padding: '0 50px' }} spacing={3}>
          <Grid item xs={12}>
            <div>
              <Typography
                style={{
                  fontSize: '14px',
                  fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                  fontWeight: '700',
                }}
              >
                Container Vazio?
              </Typography>

              <FormControlLabel
                value="yes"
                label={(
                  <span
                    style={{
                      fontSize: '14px',
                      fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                    }}
                  >
                    Sim
                  </span>
                )}
                control={<Radio />}
                checked={isEmptyContainer === true}
                disabled={isDowloaded === false || disabledEmptyContainerRadiobox}
                onChange={() => {
                  setIsEmptyContainer(true)
                }}
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label={(
                  <span
                    style={{
                      fontSize: '14px',
                      fontFamily: '“Roboto”, “Helvetica”, “Arial”, sans-serif',
                    }}
                  >
                    Não
                  </span>
                )}
                checked={isEmptyContainer === false}
                disabled={isDowloaded === false || disabledEmptyContainerRadiobox}
                onChange={() => {
                  setIsEmptyContainer(false)
                }}
              />
              <Button
                id="btn_empty_operation"
                variant="contained"
                color="secondary"
                disabled={
                  isDowloaded === false
                  || disabledEmptyContainerRadiobox
                  || ![false, true].includes(isEmptyContainer)
                }
                onClick={saveEmptyOperation}
              >
                Salvar
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginTop: '30px', padding: '0 50px', display: displayMarkSuspectImgs }}
          spacing={3}
        >
          <Grid item xs={12}>
            <ul
              style={{
                listStyle: 'none',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {!_.isEmpty(containerSorted)
                ? containerSorted.files
                  .filter(obj => obj.type !== 'x-ray' && !_.isEmpty(obj.urlSigned))
                  .map((f, id) => (
                    <li style={{ margin: '5px' }} key={id.toString()}>
                      <img
                        alt="previa do container"
                        id="img_container_preview"
                        src={f.urlSigned}
                        className={classes.imgContainerPreview}
                        onClick={() => handleOpenModalImage(f)}
                      />
                    </li>
                  ))
                : ''}
            </ul>
          </Grid>
          <Grid>
            <Button
              id="btn_finalize_operation"
              variant="contained"
              color="secondary"
              // disabled={suspectsAddedCount === 0}
              onClick={handleFinalizeOperation}
            >
              Finalizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(AnalyzeContainer)
