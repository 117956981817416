import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'
import { Link, useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Typography, Button, CircularProgress } from '@material-ui/core'
import { successLogin, failChangePassword } from 'redux/auth/auth.actions'
import {
  fetchUserConfig,
} from 'redux/userConfig/actions'

import { changePasswd } from 'services/apiAuth'

import Recaptcha from 'react-recaptcha'
import { toast } from 'react-toastify'
import { MaskInput } from 'components/MaskedInput'
import styles from './styles'

const sleep = m => new Promise(r => setTimeout(r, m))
const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
const onlyNumber = string => parseInt(string.replace(/[^0-9]/g, ''), 10)

const ChangePassword = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()

  const email = useSelector(state => state.auth.email)
  const changePasswordSession = useSelector(state => state.auth.changePasswordSession)
  const accessToken = useSelector(state => state.auth.access_token)
  const userConfigData = useSelector(state => state.userConfig.data)
  const userConfigFail = useSelector(state => state.userConfig.fail)

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  useEffect(() => {
    if (changePasswordSession === null) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!_.isEmpty(accessToken)) {
      if (_.isEmpty(userConfigData.role) || userConfigFail) {
        toast.error('Houve um problema no login, contate o adminstrador')
        history.push('/')
      } else if (userConfigData.role === 'supervisor') {
        history.push('/dashboard')
      } else {
        history.push('/analyze_container')
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfigData.role, userConfigFail])

  const handleSubmit = async () => {
    setLoading(true)

    try {
      const data = {
        app_id: process.env.REACT_APP_COGNITO_APP_ID,
        user_pool: process.env.REACT_APP_COGNITO_USER_POOL,
        username_email: email,
        password: password,
        user_attributes: {
          name: name,
          phone_number: `+55${onlyNumber(phoneNumber)}`,
        },
        session: changePasswordSession,
        recaptcha_token: recaptchaToken,
      }

      const res = await changePasswd(data)

      if (res) {
        const userdata = res.data
        // waits till create user data
        await sleep(8000)
        dispatch(successLogin(userdata))
        dispatch(fetchUserConfig())
        setLoading(false)
      }
    } catch (err) {
      dispatch(failChangePassword())
      if (err.response) {
        if (err.response.status === 400) {
          toast.error('Sua sessão expirou. Faça o login novamente!')
          history.push('/')
        }
      }

      setLoading(false)
      setError(true)
      setIsVerified(false)
      setRecaptchaToken(null)
    }
  }

  const verifyCaptcha = response => {
    if (response) {
      setIsVerified(true)
      setRecaptchaToken(response)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <img src="assets/images/logos/ebco.png" alt="Logo" className={classes.logo} />
        <div style={{ marginBottom: 50 }}>
          <h1 style={{ fontSize: 50 }}>Redefinir Senha</h1>
          <p style={{ fontSize: 18 }}>Redefina sua senha para acessar a plataforma!</p>
        </div>
      </div>
      <div className={classes.main}>
        <h1 className={classes.titlePage}>Redefinir Senha</h1>
        <div>
          <TextField
            id="name"
            label="Nome"
            className={classes.textField}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!(name.length !== 0 && name.length < 2)}
          />
          <MaskInput
            className={classes.textField}
            autoComplete="tel"
            label="Telefone"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            name="phone_number"
          />

          <TextField
            id="password"
            autoComplete="new-password"
            label="Senha"
            className={classes.textField}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            error={password.length !== 0 ? !strongRegex.test(password) : false}
            helperText={
              password.length !== 0
                ? strongRegex.test(password)
                  ? false
                  : 'A senha precisa ter pelo menos 1 letra maiuscula, 1 minuscula, 1 número e um caracter especial, e no mínimo de 8 caracteres.'
                : false
            }
          />

          <TextField
            id="confirmPassword"
            autoComplete="off"
            label="Confirmar Senha"
            className={classes.textField}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            error={!!(confirmPassword.length !== 0 && confirmPassword !== password)}
            helperText={confirmPassword !== password ? 'As senhas precisam ser iguais.' : null}
          />
          {error ? (
            <p>
              <strong style={{ color: 'red', fontSize: 16 }}>
                Erro ao redefinir a senha, verifique seus dados e tente novamente.
              </strong>
            </p>
          ) : null}
        </div>

        <div style={{ textAlign: 'center', width: 300 }}>
          {!isVerified ? (
            <Recaptcha
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              render="explicit"
              onloadCallback={() => {}}
              verifyCallback={verifyCaptcha}
              hl="pt-BR"
            />
          ) : (
            <React.Fragment>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={handleSubmit}
                disabled={!!loading}
              >
                {loading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{
                      width: 25,
                      height: 25,
                    }}
                  />
                ) : (
                  'Confirmar'
                )}
              </Button>
            </React.Fragment>
          )}
          <Typography>
            Entrar com outra conta.
            {' '}
            <Link to="/" className={classes.link}>
              Fazer Login
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(ChangePassword)
