/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import {
  Grid,
  Fab,
  Input,
  Card,
  CardContent,
  CardHeader,
  ListItem,
  List,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { HourglassEmpty } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'

import { vehiclesDashboard } from 'services/loadAnalysisApi'

import styles from './styles'

const Dashboard = () => {
  const classes = styles()

  const apiKey = useSelector(state => state.auth.api_key)

  const [greenProcesses, setGreenProcesses] = useState({ qty: 0, trucks: null, })
  const [greyProcesses, setGreyProcesses] = useState({ qty: 0, trucks: null, })
  const [redProcesses, setRedProcesses] = useState({ qty: 0, trucks: null, })
  const [yellowProcesses, setYellowProcesses] = useState({ qty: 0, trucks: null, })
  const [beginDate, setBeginDate] = useState(moment().subtract(6, 'months').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

  const loadProcesses = async (beginVal, endVal) => {
    setGreenProcesses({ qty: 0, trucks: null, })
    setGreyProcesses({ qty: 0, trucks: null, })
    setRedProcesses({ qty: 0, trucks: null, })
    setYellowProcesses({ qty: 0, trucks: null, })

    try {
      const response = await vehiclesDashboard(apiKey, beginVal, endVal)
      const resData = response.data

      setGreenProcesses(resData.green)
      setYellowProcesses(resData.yellow)
      setRedProcesses(resData.red)
      setGreyProcesses(resData.grey)
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }
    }
  }

  useEffect(() => {
    loadProcesses(beginDate, endDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFilterLastTimeClick = (val) => {
    const finalDate = moment().format('YYYY-MM-DD')
    setBeginDate(val.slice(0, 10))
    setEndDate(finalDate)
    loadProcesses(val, finalDate)
  }

  const handleFilterClick = () => {
    loadProcesses(beginDate, endDate)
  }

  const renderListItem = (processes) => {
    if (processes.trucks.length != null) {
      return processes.trucks.map((item, i) => (
        <ListItem key={i.toString()} className={classes.listItem}>
          <a
            href={`/consult_container?container=${item.object_identifier}`}
            style={{ textDecoration: 'none', color: '#666666' }}
          >
            {item.object_identifier}
          </a>
        </ListItem>
      ))
    }

    return ''
  }

  return (
    <div className={classes.root}>
      <Grid container style={{ backgroundColor: '#666666', padding: '50px' }}>
        <Grid item xs={4}>
          <Typography className={classes.titleCardWhite}>Status</Typography>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#ffffff',
                fontSize: '14px',
              }}
            >
              <HourglassEmpty />
              {' '}
              <span>Filtrar por</span>
            </div>
            <div style={{ marginLeft: '5px' }}>
              <Fab
                size="small"
                className={classes.fab}
                onClick={() => {
                  handleFilterLastTimeClick(
                    moment().subtract(15, 'minutes').format('YYYY-MM-DD HH:mm')
                  )
                }}
              >
                15m
              </Fab>
              <Fab
                size="small"
                className={classes.fab}
                onClick={() => {
                  handleFilterLastTimeClick(
                    moment().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm'),
                  )
                }}
              >
                30m
              </Fab>
              <Fab
                size="small"
                className={classes.fab}
                onClick={() => {
                  handleFilterLastTimeClick(
                    moment().subtract(1, 'hour').format('YYYY-MM-DD HH:mm'),
                  )
                }}
              >
                1h
              </Fab>
              <Fab
                size="small"
                className={classes.fab}
                onClick={() => {
                  handleFilterLastTimeClick(
                    moment().subtract(2, 'hour').format('YYYY-MM-DD HH:mm'),
                  )
                }}
              >
                2h
              </Fab>
              <Fab
                size="small"
                className={classes.fab}
                onClick={() => {
                  handleFilterLastTimeClick(
                    moment().subtract(6, 'hour').format('YYYY-MM-DD HH:mm'),
                  )
                }}
              >
                6h
              </Fab>
              <Fab
                size="small"
                className={classes.fab}
                onClick={() => {
                  handleFilterLastTimeClick(
                    moment().subtract(12, 'hour').format('YYYY-MM-DD HH:mm'),
                  )
                }}
              >
                12h
              </Fab>
              <Fab
                size="small"
                className={classes.fab}
                onClick={() => {
                  handleFilterLastTimeClick(
                    moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm'),
                  )
                }}
              >
                1 d
              </Fab>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={5}
          style={{ display: 'flex', justifyContent: 'end', flexDirection: 'column' }}
        >
          <div>
            <span style={{ color: '#ffffff', fontSize: '14px' }}>Período de </span>
            <Input
              style={{ color: '#ffffff' }}
              type="date"
              disableUnderline
              className={classes.inputPeriod}
              value={beginDate}
              onChange={(e) => {
                setBeginDate(e.target.value)
              }}
            />
            {' '}
            <span style={{ color: '#ffffff', fontSize: '14px' }}> até </span>
            <Input
              style={{ color: '#ffffff' }}
              type="date"
              disableUnderline
              className={classes.inputPeriod}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value)
              }}
            />
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              style={{ marginLeft: '8px' }}
              onClick={() => {
                handleFilterClick()
              }}
            >
              Filtrar
            </Fab>
          </div>
        </Grid>
        <Grid item xs={3} />
      </Grid>
      <Grid container style={{ marginTop: '30px', padding: '0 50px' }} spacing={3}>
        <Grid item xs={3}>
          <Card style={{ border: '1px solid #00aa00', height: '395.5px' }}>
            <a href="/containers_list?process_status=ok" style={{ textDecoration: 'none' }}>
              <CardHeader
                title={`APROVADOS: ${greenProcesses.qty}`}
                style={{ backgroundColor: '#00aa00', textAlign: 'center' }}
                classes={{ title: classes.titleCardWhite }}
              />
            </a>
            <CardContent>
              {greenProcesses.trucks != null ? (
                <List>{renderListItem(greenProcesses)}</List>
              ) : (
                <div style={{ margin: '35% 40%' }}>
                  <CircularProgress color="secondary" />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ border: '1px solid #f7b500', height: '395.5px' }}>
            <a
              href="/containers_list?process_status=inconsistentes"
              style={{ textDecoration: 'none' }}
            >
              <CardHeader
                title={`INCONSISTENTES: ${yellowProcesses.qty}`}
                style={{ backgroundColor: '#f7b500', textAlign: 'center' }}
                classes={{ title: classes.titleCard }}
              />
            </a>
            <CardContent>
              {yellowProcesses.trucks != null ? (
                <List>{renderListItem(yellowProcesses)}</List>
              ) : (
                <div style={{ margin: '35% 40%' }}>
                  <CircularProgress color="secondary" />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ border: '1px solid red', height: '395.5px' }}>
            <a
              href="/containers_list?process_status=suspeitos"
              style={{ textDecoration: 'none' }}
            >
              <CardHeader
                title={`SUSPEITOS: ${redProcesses.qty}`}
                style={{ backgroundColor: 'red', textAlign: 'center' }}
                classes={{ title: classes.titleCardWhite }}
              />
            </a>
            <CardContent>
              {redProcesses.trucks != null ? (
                <List>{renderListItem(redProcesses)}</List>
              ) : (
                <div style={{ margin: '35% 40%' }}>
                  <CircularProgress color="secondary" />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ border: '1px solid #cccccc', height: '395.5px' }}>
            <a
              href="/containers_list?process_status=pendentes"
              style={{ textDecoration: 'none' }}
            >
              <CardHeader
                title={`PENDENTES: ${greyProcesses.qty}`}
                style={{ backgroundColor: '#cccccc', textAlign: 'center' }}
                classes={{ title: classes.titleCard }}
              />
            </a>
            <CardContent>
              {greyProcesses.trucks != null ? (
                <List>{renderListItem(greyProcesses)}</List>
              ) : (
                <div style={{ margin: '35% 40%' }}>
                  <CircularProgress color="secondary" />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(Dashboard)
