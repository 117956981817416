import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Typography, Button, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { login } from 'redux/auth/auth.actions'
import { Link } from 'react-router-dom'
import { confirmRecoverPassword } from 'services/apiAuth'
import Recaptcha from 'react-recaptcha'
import styles from './styles'

class Index extends React.PureComponent {
  state = {
    code: '',
    username_email: '',
    password: '',
    loading: false,
    error: false,
    isVerified: false,
    recaptchaToken: null,
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value })
  }

  handleSubmit = async () => {
    await this.setState({ loading: true, error: false })
    try {
      const formData = new FormData()
      formData.append('code', this.state.code)
      formData.append('username_email', this.state.username_email)
      formData.append('password', this.state.password)
      formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
      formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)
      formData.append('recaptcha_token', this.state.recaptchaToken)

      const res = await confirmRecoverPassword(formData)
      if (res.status === 200) {
        await this.setState({ loading: false })

        this.props.history.push({
          pathname: '/',
        })
      }
    } catch (error) {
      await this.setState({ loading: false, isVerified: false, recaptchaToken: null })
    }
  }

  strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')

  emailRegex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)

  verifyCaptcha = response => {
    if (response) {
      this.setState({
        isVerified: true,
        recaptchaToken: response,
      })
    }
  }

  render() {
    const { classes } = this.props
    const email = this.props.authsReducer.destination
    const { loading, error, password, isVerified } = this.state
    return (
      <div className={classes.root}>
        <div className={classes.sidebar}>
          <img src="assets/images/logos/ebco.png" alt="Logo" className={classes.logo} />
          <div style={{ marginBottom: 50 }}>
            <h1 style={{ fontSize: 50 }}>Confirmar Cadastro</h1>
            <p style={{ fontSize: 18 }}>Faça a confirmação do cadastro em que será alterada a senha!</p>
          </div>
        </div>
        <div className={classes.main}>
          <h1 className={classes.titlePage}>Digite o código recebido por email:</h1>
          <p>
            O código foi enviado para o email:
            {email}
          </p>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <form>
              <TextField
                id="code"
                label="Código recebido por email"
                className={classes.textField}
                value={this.state.code}
                onChange={this.handleChange('code')}
                autoComplete="off"
                required={true}
              />
              <TextField
                id="email"
                label="Nome de Usuário ou E-mail utilizado"
                className={classes.textField}
                value={this.state.username_email}
                onChange={this.handleChange('username_email')}
                type="email"
                required={true}
              />
              <TextField
                id="password"
                label="Senha"
                className={classes.textField}
                onChange={this.handleChange('password')}
                type="password"
                error={password.length !== 0 ? !this.strongRegex.test(password) : false}
                helperText={
                  password.length !== 0
                    ? this.strongRegex.test(password)
                      ? false
                      : 'A senha precisa ter pelo menos 1 letra maiuscula, 1 minuscula, 1 número e um caracter especial, e no mínimo de 8 caracteres.'
                    : false
                }
                autoComplete="new-password"
                required={true}
              />
            </form>
            {error ? (
              <p>
                <strong style={{ color: 'red', fontSize: 16 }}>
                  Já existe uma conta cadastrada com esse email.
                  <br />
                  Por favor, refaça o cadastro utilizando um email diferente.
                </strong>
              </p>
            ) : null}
          </div>

          <div style={{ textAlign: 'center', width: 300 }}>
            {!isVerified ? (
              <Recaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                render="explicit"
                onloadCallback={() => {}}
                verifyCallback={this.verifyCaptcha}
                hl="pt-BR"
              />
            ) : (
              <React.Fragment>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleSubmit}
                  disabled={!!loading}
                >
                  {loading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{
                        width: 25,
                        height: 25,
                      }}
                    />
                  ) : (
                    'Confirmar'
                  )}
                </Button>
              </React.Fragment>
            )}
            <Typography>
              Lembrei minha senha.
              {' '}
              <Link to="/">Entrar</Link>
            </Typography>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authsReducer: state.auth,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Index))
