export const USER_CONFIG_CLEAR = 'USER_CONFIG_CLEAR'

export const USER_CONFIG_FETCH = 'USER_CONFIG_FETCH'
export const USER_CONFIG_FETCH_FAILED = 'USER_CONFIG_FETCH_FAILED'
export const USER_CONFIG_FETCH_SUCCEEDED = 'USER_CONFIG_FETCH_SUCCEEDED'

export const userConfigClear = () => ({
  type: USER_CONFIG_CLEAR,
})

export const fetchUserConfig = () => ({
  type: USER_CONFIG_FETCH,
})
