import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240

export default makeStyles(theme => ({
  root: {
    width: '100%',
  },
  appbar: {
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    minHeight: 'inherit',
    '@media(max-width: 1280px)': {
      padding: 10,
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  sectionLogo: {
    display: 'flex',
    marginRight: '2rem',
    alignItems: 'center',
    minHeight: 'inherit',
    '@media(max-width: 1280px)': {
      marginBottom: 10,
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 'inherit',
  },
  logoContainerImg: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'inherit',
    backgroundColor: '#fff',
    paddingLeft: 10,
    paddingRight: 10,
  },
  logoImg: {
    height: 50,
  },
  menubar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    minHeight: 'inherit',
    '@media(max-width: 1280px)': {
      flexWrap: 'wrap',
    },
  },
  sectionUser: {
    display: 'flex',
    minHeight: 'inherit',
    '@media(max-width: 1280px)': {
      marginTop: 30,
      paddingTop: 15,
      borderTop: '1px solid #CECECE',
    },
  },
  listNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'inherit',
    '@media(max-width: 1280px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 240,
    },
    [theme.breakpoints.up('lg')]: {
      height: 'inherit',
    },
  },
  listNavItem: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
      cursor: 'pointer',
    },

    '@media(max-width: 1280px)': {
      padding: 10,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
      '&:hover': {
        backgroundColor: '#cecece',
      },
    },
  },
  listNavItemHover: {
    backgroundColor: theme.palette.primary.hover,
  },
  listNavItemUsername: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    '@media(max-width: 1280px)': {
      padding: 10,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
      '&:hover': {
        backgroundColor: '#cecece',
      },
    },
  },
  listNavItemSep: {
    marginLeft: 10,
  },
  listNavItemAnchor: {
    textDecoration: 'none',
    color: '#ffffff',
    display: 'flex',
    height: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      color: '#333',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#ffffff',
    marginRight: 10,
    marginLeft: 5,
    '@media(max-width: 1280px)': {
      color: '#333',
    },
  },
  logolf: {
    display: 'none',
    '@media(max-width: 1280px)': {
      color: '#333',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      marginRight: 10,
      marginLeft: 5,
    },
  },
  dropdown: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.lighter,
  },
  sectionDesktop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 'inherit',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      height: 50,
      flexGrow: 1,
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  version: {
    bottom: 2,
    color: '#333',
    position: 'absolute',
    // visibility: 'hidden',
  },
  containerTitle: {
    marginLeft: 15,
    display: 'none',
  },
  title: {
    fontWeight: 'bold',
  },
}))
