/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import {
  Grid,
  Input,
  Table,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  Fab,
  TableRow,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Typography,
  TablePagination,
} from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'

import { withStyles } from '@material-ui/core/styles'

import moment from 'moment'

import { listContainers, listSites } from 'services/loadAnalysisApi'

import styles from './styles'

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 14,
  },
  body: {
    fontSize: 16,
    color: '#666666',
  },
}))(TableCell)

const ProcessStatusWithIcon = (props) => {
  let colorStatus = 'white'
  let textStatus = ''

  switch (props.status) {
    case 'green':
      colorStatus = 'green'
      textStatus = 'OK'
      break
    case 'yellow':
      colorStatus = 'yellow'
      textStatus = 'Inconsistente'
      break
    case 'red':
      colorStatus = 'red'
      textStatus = 'Suspeito'
      break
    case 'grey':
      colorStatus = 'gray'
      textStatus = 'Pendente'
      break
    default:
      colorStatus = 'white'
      textStatus = ''
      break
  }
  return (
    <span style={{ color: colorStatus }}>
      <FiberManualRecord />
      {' '}
      <span style={{ fontFamily: 'Roboto Regular', color: '#666666' }}>{textStatus}</span>
    </span>
  )
}

const StatusVeredictTxt = (props) => {
  const { veredict } = props
  let colorStatus = 'white'

  switch (veredict) {
    case 'No Suspect':
      colorStatus = 'green'
      break
    case 'With Suspect':
      colorStatus = 'red'
      break
    default:
      colorStatus = 'white'
      break
  }

  return (
    <span style={{ color: colorStatus }}>
      <FiberManualRecord />
      {' '}
    </span>
  )
}

const ConsultContainer = () => {
  const classes = styles()

  const apiKey = useSelector(state => state.auth.api_key)

  const [data, setData] = useState([])
  const [searching, setSearching] = useState(false)
  const [containerFilter, setContainerFilter] = useState('')
  const [selectTypeSearch, setSelectTypeSearch] = useState('container')
  const [pagination, setPagination] = useState({
    perPage: 15,
    currentPage: 1,
    totalItens: 0,
  })

  const loadContainers = async (page = '1', container = containerFilter, typeSearch = selectTypeSearch) => {
    setData([])
    setSearching(true)

    try {
      const response = typeSearch === 'container'
        ? await listContainers(apiKey, container, page)
        : await listSites(apiKey, container, page)
      // const response = await listContainers(apiKey, container)

      setData(response.data.vehicles)
      setPagination({
        perPage: parseInt(response.headers['x-per-page'], 10),
        currentPage: parseInt(response.headers['x-page'], 10),
        totalItens: parseInt(response.headers['x-total'], 10),
      })
      setSearching(false)
    } catch (err) {
      if (err.response) {
        alert(_.get(err, 'response.data.msg_errors.0.msg'))
      } else if (err.request) {
        alert(err.request)
      } else {
        alert(err.message)
      }
    }
  }

  useEffect(() => {
    const url = window.location.href
    const container = url.split('container=')

    if (container[1]) {
      setContainerFilter(container[1])
      setSelectTypeSearch('container')
    }

    loadContainers(1, container[1], 'container')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = (e, page) => {
    loadContainers((page + 1).toString())
  }

  const handleFilterClick = () => {
    loadContainers()
  }

  const sanitizeDate = (date) => {
    try {
      const dateTxt = moment(date).format('DD-MM-YYYY HH:mm')
      return dateTxt === 'Invalid date' ? '' : dateTxt
    } catch (e) {
      return ''
    }
  }

  return (
    <div className={classes.root}>

      <Grid container style={{ backgroundColor: '#666666', padding: '50px' }}>
        <Grid item xs={7}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <FormControl>
                <Select
                  value={selectTypeSearch}
                  onChange={(e) => {
                    setSelectTypeSearch(e.target.value)
                  }}
                  style={{ width: '120px', color: '#ffffff' }}
                >
                  <MenuItem value="container">container</MenuItem>
                  <MenuItem value="site">site</MenuItem>
                </Select>
              </FormControl>
              <Input
                style={{ color: '#ffffff' }}
                disableUnderline
                className={classes.inputPeriod}
                value={containerFilter}
                onChange={(e) => {
                  setContainerFilter(e.target.value)
                }}
              />
              <Fab
                variant="extended"
                size="medium"
                color="secondary"
                style={{ marginLeft: '8px' }}
                onClick={() => {
                  handleFilterClick()
                }}
              >
                Filtrar
              </Fab>
            </div>
          </div>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell align="center">CONTAINER</CustomTableCell>
              <CustomTableCell align="center">DATA SCAN</CustomTableCell>
              <CustomTableCell align="center">TRUCK ID</CustomTableCell>
              <CustomTableCell align="center">SITE</CustomTableCell>
              <CustomTableCell align="center">DATA 1º VEREDITO</CustomTableCell>
              <CustomTableCell align="center">VEREDITOS</CustomTableCell>
              <CustomTableCell align="left">STATUS</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, id) => (
              <TableRow className={classes.row} key={id.toString()}>
                <CustomTableCell align="right">{row.object_identifier}</CustomTableCell>
                <CustomTableCell align="right">
                  {sanitizeDate(row.scan.date)}
                </CustomTableCell>
                <CustomTableCell align="right">{row.scan.truck_id}</CustomTableCell>
                <CustomTableCell align="right">{row.scan.site}</CustomTableCell>
                <CustomTableCell align="right">
                  {row.operations[0] == null ? '' : sanitizeDate(row.operations[0].date)}
                </CustomTableCell>
                <CustomTableCell align="right">
                  {row.operations.map((s, idS) => (
                    <StatusVeredictTxt key={idS.toString()} veredict={s.veredict} />
                  ))}
                </CustomTableCell>
                <CustomTableCell align="left">
                  <ProcessStatusWithIcon status={row.internal_status} />
                </CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data.length === 0 && searching === false ? (
          <Typography style={{ padding: '10px' }}>Nenhum dado encontrado!</Typography>
        ) : (
          ''
        )}
      </Paper>
      <TablePagination
        rowsPerPageOptions={[15]}
        component="div"
        count={pagination.totalItens}
        rowsPerPage={pagination.perPage}
        page={pagination.currentPage - 1}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={handleChangePage}
      />
      <Grid>
        {searching ? (
          <div style={{ margin: '10% 50%' }}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          ''
        )}
      </Grid>
    </div>
  )
}

export default withStyles(styles)(ConsultContainer)
